<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex>
        <!-- <v-card> -->
        <!-- <v-card-title class="elevation-0">
          <span class="itemHeading">Organisations List</span>
          <v-spacer></v-spacer>
        </v-card-title> -->
        <v-layout wrap justify-start>
          <v-flex xs12 pa-4>
            <v-layout wrap>
              <v-flex align-self-center text-left>
                <span style="font-family: poppinssemibold"
                  >EMPLOYEE SPLIT VIEW >>> {{ project }}</span
                >
              </v-flex>
              <v-flex xl3 lg3 md3 sm3 xs12 pb-2 pl-6 text-right> </v-flex>
            </v-layout>
            <v-progress-linear
              :color="appColor"
              rounded
              value="100"
              height="2px"
            ></v-progress-linear>
          </v-flex>
          <template v-if="organisations.length > 0">
            <v-flex
              xs12
              md6
              align-self-start
              v-for="(item, i) in organisations"
              :key="i"
              fill-height
              pa-4
            >
              <v-card
                tile
                elevation="2"
                outlined
                style="
                  border-radius: 0px 0px 20px !important;
                  border-bottom: 5px solid #5f4b8bff;
                "
              >
                <v-layout wrap justify-center py-4>
                  <v-flex xs6 align-self-start text-center pa-3>
                    <span style="font-family: poppinsbold"> Employee </span>
                  </v-flex>
                  <v-flex xs6 align-self-start text-center pa-3>
                    <span style="font-family: poppinsbold">Split Amount </span>
                  </v-flex>
                  <v-flex xs10>
                    <v-divider></v-divider>
                  </v-flex>
                  <v-flex xs5 align-self-start text-center pa-3>
                    <router-link :to="'/userView?id=' + item.empId._id">
                      <span style="font-family: poppinsmedium">{{
                        item.empId.eid.name
                      }}</span>
                    </router-link>
                  </v-flex>
                  <v-flex xs1 pb-1>
                    <v-divider vertical></v-divider>
                  </v-flex>
                  <v-flex xs5 align-self-start text-center pa-3>
                    <span style="font-family: poppinsmedium">{{
                      item.amount
                    }}</span>
                  </v-flex>
                  <v-flex xs12 px-3>
                    <v-expansion-panels>
                      <v-expansion-panel>
                        <v-expansion-panel-header
                          color="grey"
                          class="white--text"
                        >
                          Fund Details
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-simple-table>
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th class="text-left">Date</th>

                                  <th class="text-left">Already received</th>
                                  <th class="text-left">
                                    Committed and confirmed
                                  </th>
                                  <th class="text-left">
                                    Committed but not confirmed
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  class="text-left"
                                  v-for="(item2, i) in item.projectTargetData"
                                  :key="i"
                                >
                                  <td>{{ formatDate(item2.create_date) }}</td>
                                  <td>{{ item2.amountAchieved }}</td>
                                  <td>{{ item2.committed }}</td>
                                  <td>{{ item2.notconfirmed }} </td> 
                                </tr>
                                <tr class="text-left">
                                  <td></td>
                                  <td>Total</td>
                                  <td>Total</td>
                                  <td>Total</td>
                                </tr>
                                <tr class="text-left">
                                  <td></td>
                                  <td>
                                    <b> {{ item.amountAchieved }} (INR) </b>
                                  </td>
                                  <td>
                                    <b>{{ item.committed }} (INR) </b>
                                  </td>
                                  <td>
                                    <b>{{ item.notconfirmed }} (INR) </b>
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-flex>
                  <!-- <v-flex xs12 sm4 pa-2>
                  <v-btn
                    color="#0063B2FF"
                    style="font-family: poppinsmedium"
                    x-small
                    block
                    tile
                    dark
                    @click="editcat(item)"
                    >Edit</v-btn
                  >
                </v-flex>
                <v-flex xs12 sm4 pa-2>
                  <v-dialog persistent v-model="item.delete" max-width="600px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        style="font-family: poppinsmedium"
                        class="mr-2"
                        dark
                        block
                        tile
                        v-on="on"
                        v-bind="attrs"
                        color="#ED2B33FF"
                      >
                        Delete
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title
                        >Are you sure you want to delete this
                        Organisation?</v-card-title
                      >
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="#ED2B33FF"
                          text
                          @click="item.delete = false"
                          >Cancel</v-btn
                        >
                        <v-btn color="#0063B2FF" text @click="deleteItem(item)"
                          >OK</v-btn
                        >
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-flex> -->
                  <v-flex xs12 text-right pt-2 pr-1 v-if="userType == 'SuperAdmin'">
                    <v-btn
                      text
                      x-small
                      dark
                      color="error"
                      @click="deleteDial(item._id)"
                    >
                      delete
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </template>
          <v-flex xs12 v-else>
            <span style="font-family: poppinsbold" class="RB"
              >Empty List....!</span
            >
          </v-flex>
          <v-dialog persistent v-model="editdialog" max-width="50%">
            <v-card tile>
              <!-- <v-card-title>
                <span class="headline">Edit Organisation</span>
              </v-card-title> -->
              <!-- <v-card-text> -->
              <v-layout wrap>
                <v-flex xs12 style="background-color: #5f4b8bff">
                  <span
                    style="
                      color: #ffffff;
                      font-size: 20px;
                      font-family: poppinsbold;
                      padding: 2px;
                    "
                    class="RB"
                    >EDIT ORGANISATION</span
                  >
                </v-flex>
              </v-layout>
              <v-layout wrap pa-12>
                <v-flex xs12 text-left>
                  Organisation Name*
                  <br />
                  <v-text-field
                    v-model="editingitem.name"
                    placeholder="Organisation Name"
                    :rules="[rules.required]"
                    outlined
                    dense
                    required
                    hide-details
                  >
                  </v-text-field>
                </v-flex>
              </v-layout>
              <!-- </v-card-text> -->
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="#ED2B33FF" text @click="getdData"> Close </v-btn>
                <v-btn
                  v-if="editingitem.name"
                  color="#0063B2FF"
                  text
                  @click="edit(editingitem._id)"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog width="400px" v-model="SplitDeleteDialog">
            <v-card width="400px">
              <v-layout wrap justify-center pa-4>
                <v-flex xs12 dark dense pb-4>
                  <b> Are you sure you want to delete this split ....? </b>
                </v-flex>
                <v-flex xs12 text-right>
                  <v-btn
                    dark
                    x-small
                    color="error"
                    @click="SplitDeleteDialog = false"
                    >Cancel</v-btn
                  >
                  <v-btn
                    dark
                    x-small
                    color="primary"
                    class="ml-2"
                    @click="DeleteSplit()"
                  >
                    Ok</v-btn
                  >
                </v-flex>
              </v-layout>
            </v-card>
          </v-dialog>
        </v-layout>
        <!-- </v-card> -->
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
// import ImageComp from  "@/components/Common/singleImage";
import axios from "axios";
export default {
  data() {
    return {
      SplitDeleteDialog: false,
      splitDeleteId: null,
      project: null,
      showsnackbar: false,
      ServerError: false,
      msg: null,
      pages: 0,
      dialog: false,
      editdialog: false,
      editingitem: [],
      name: "",
      pageCount: 1,
      appLoading: false,
      editedIndex: -1,
      currentPage: 1,
      g: false,
      perPage: 4,
      totalData: 0,
      totalRows: 0,
      dialogDelete: false,
      search: "",
      itemname: "",
      addcat: false,
      rules: {
        required: (value) => !!value || "Required.",
      },
      organisations: [],
      data: [],
      coverImageFile: null,
      selectedFiles: [],
      image: null,
      coverImageArray: [],
      formDataCover: new FormData(),
      flagg: true,
    };
  },
  components: {
    // ImageComp,
  },
  mounted() {
    this.getData();
  },
  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  computed: {
    userType() {
      return this.$store.state.userType;
    },
  },

  methods: {
    deleteDial(item) {
      this.splitDeleteId = item;
      this.SplitDeleteDialog = true;
    },

    DeleteSplit() {
      this.appLoading = true;
      axios({
        url: "remove/split/target",
        method: "post",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: this.splitDeleteId,
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.appLoading = false;
            this.SplitDeleteDialog = false;
            this.getData();
          } else {
            this.showsnackbar = true;
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err());
        });
    },

    formatDate(item) {
      return item.substring(0, 10);
    },
    getdData() {
      this.editdialog = false;
      this.getData();
    },

    deleteItem(cat) {
      var data = {};
      data["id"] = cat._id;
      axios({
        url: "/organization/remove",
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: data,
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.showsnackbar = true;

            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "splittarget/employee/data",
        method: "post",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          splitId: this.$route.query.id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.organisations = response.data.data;
          this.totalData = response.data.count;
          this.project = response.data.data[0].targetid.projectid.projectName;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    dialogclose() {
      this.name = null;
      this.dialog = false;
    },

    addOrganisation() {
      var data = {};
      data["name"] = this.name;
      axios({
        url: "/add/organization",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Added Sucessfully";
            this.showsnackbar = true;
            this.name = null;
            this.dialog = false;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editcat(item) {
      this.editingitem = item;
      this.editdialog = true;
    },
    edit() {
      var data = {};
      data["id"] = this.editingitem._id;
      data["name"] = this.editingitem.name;
      axios({
        url: "/organization/edit",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.editdialog = false;

          this.appLoading = false;
          if (response.data.status) {
            // if (this.coverImageFile) {
            //   this.uploadCoverImages(this.editingitem._id);
            // }
            this.msg = "Edited Sucessfully";
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
